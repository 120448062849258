import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      input {
        margin-bottom: 1rem;
      }
    `;
  }};
`;
