import React, { HTMLAttributes, ReactNode } from 'react';
import { OffCanvas, OffCanvasBody, OffCanvasHeader } from '@/components/offcanvas';
import { useNavbarContext } from '@/components/navbar/context/NavbarContext';
import { ExchangeRateWrapper, Nav, NavList, NavListItem } from './Navigation.styles';

import { MenuDropdown } from 'src/components/navigation/DropdownMenu';
import ExchangeRates from '@/components/exchange/ExchangeRates';
import Text from '@/components/typography/Text/Text';
import { useAppContext } from '@/context/AppContext';

// let navbar = data.navbar;

export interface NavigationProps extends HTMLAttributes<any> {
  className?: string;
  children?: ReactNode;
}

const Navigation = (props: NavigationProps) => {
  const { navigation } = useAppContext();
  const { navbar } = navigation || {};
  const { open, toggle } = useNavbarContext();

  if (!navbar) return null;
  return (
    <>
      <OffCanvas open={open} toggle={toggle} scrolling={false} size="sm" screen="mobile" aria-labelledby="Menu">
        <OffCanvasHeader title="Menu" id="Menu" />
        <OffCanvasBody>
          <Nav aria-label="Main Navigation">
            <NavList role="menu">
              {navbar.map((item: NavbarData) => (
                <NavListItem
                  key={item.id}
                  id={`navitem-${item.id}`}
                  onMouseEnter={(e) => {
                    e.currentTarget.classList.add('show-submenu');
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.classList.remove('show-submenu');
                  }}
                >
                  <MenuDropdown {...item} parentId={`navitem-${item.id}`} />
                </NavListItem>
              ))}
            </NavList>
          </Nav>
          <ExchangeRateWrapper>
            <Text margin="sm" weight="bold">
              Câmbio
            </Text>
            <ExchangeRates appearance="vertical" />
          </ExchangeRateWrapper>
        </OffCanvasBody>
      </OffCanvas>
    </>
  );
};

export default Navigation;
