import styled, { css } from 'styled-components';
import { OffCanvasProps } from './OffCanvas';
import { Element as OffCanvasHeaderElement } from '../OffCanvasHeader/OffCanvasHeader.styles';
import { Element as OffCanvasBodyElement } from '../OffCanvasBody/OffCanvasBody.styles';
import { Element as OffCanvasOverlayElement } from '../OffCanvasOverlay/OffCanvasOverlay.styles';

const baseOffCanvasStyle = css<Pick<OffCanvasProps, 'open' | 'canvas' | 'screen' | 'size'>>`
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.box_shadow.base};
  position: fixed;
  top: 0;
  bottom: 0;
  background: ${({ theme }) => theme.color.white};

  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2001;

  // Canvas Size control
  ${({ size }) => {
    switch (size) {
      case 'full':
        return css`
          width: 100vw;
        `;
      case 'lg':
        return css`
          width: 50vw;
        `;
      case 'md':
        return css`
          width: 37.5rem;
        `;
      case 'sm':
      default:
        return css`
          width: 90vw;
          @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
            width: 25rem;
          }
        `;
    }
  }};

  // Canvas direction
  ${({ canvas }) => {
    if (!canvas || canvas === 'start') {
      return css`
        left: 0;
        transform: translateX(-110%);
      `;
    } else {
      return css`
        right: 0;
        transform: translateX(100%);
      `;
    }
  }};

  ${({ open }) => {
    if (open) {
      return css`
        transform: translate(0, 0);
      `;
    }
  }}
`;

export const Element = styled.div<Pick<OffCanvasProps, 'open' | 'canvas' | 'screen' | 'size'>>`
  // Shows only on mobile
  ${OffCanvasHeaderElement} {
    display: none;
  }

  ${OffCanvasBodyElement} {
    flex-grow: initial;
    overflow: initial;
    padding: initial;
  }

  + ${OffCanvasOverlayElement} {
    display: none;
  }

  ${({ theme, canvas }) => {
    // This will make the canvas do not move around when switching from Desktop to Mobile screen sizes
    return (
      (!canvas || canvas === 'start') &&
      css`
        left: -1000px;
      `
    );
  }};

  ${({ theme, screen }) => {
    if (!screen || screen === 'mobile') {
      return css`
        @media screen and (max-width: ${({ theme }) => theme.breakpoint.lg}) {
          ${OffCanvasHeaderElement} {
            display: flex;
          }

          ${OffCanvasBodyElement} {
            padding: ${({ theme }) => theme.spacing.base};
            flex-grow: 1;
            overflow-x: auto;
          }

          + ${OffCanvasOverlayElement} {
            display: initial;
          }

          ${baseOffCanvasStyle};
        }
      `;
    }

    if (screen === 'desktop') {
      return css`
        @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
          ${OffCanvasHeaderElement} {
            display: flex;
          }

          ${OffCanvasBodyElement} {
            padding: ${({ theme }) => theme.spacing.base};
            flex-grow: 1;
            overflow-x: auto;
          }

          + ${OffCanvasOverlayElement} {
            display: initial;
          }

          ${baseOffCanvasStyle};
        }
      `;
    }

    if (screen === 'all') {
      return css`
        ${OffCanvasHeaderElement} {
          display: flex;
        }

        ${OffCanvasBodyElement} {
          padding: ${({ theme }) => theme.spacing.base};
          flex-grow: 1;
          overflow-x: auto;
        }

        + ${OffCanvasOverlayElement} {
          display: initial;
        }

        ${baseOffCanvasStyle};
      `;
    }
  }}
`;
