import React from 'react';
import { ResultItemPlaceholder, ResultsContainer, Section } from '@/features/search/SearchResult/SearchResult.styles';

export interface SearchLoadingProps {}

const SearchLoading = ({ ...props }: SearchLoadingProps) => {
  return (
    <Section>
      <ResultsContainer>
        <ResultItemPlaceholder />
        <ResultItemPlaceholder />
        <ResultItemPlaceholder />
        <ResultItemPlaceholder />
      </ResultsContainer>
    </Section>
  );
};

export default SearchLoading;
