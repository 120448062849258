import React from 'react';
import { Element } from '@/components/header/NavbarInfo/NavbarInfo.styles';
import Container from '@/components/container/Container';
import ExchangeRates from '@/components/exchange/ExchangeRates';
import ContactMenu from '@/components/navigation/ContactMenu/ContactMenu';

export interface NavbarInfoProps {
  className?: string;
}

const NavbarInfo = (props: NavbarInfoProps) => {
  return (
    <Element>
      <Container flex justify="space-between" align="center">
        <ExchangeRates />
        <ContactMenu />
      </Container>
    </Element>
  );
};

export default NavbarInfo;
