import styled, { css } from 'styled-components';
import { AuthUserProps } from './AuthUserButton';

export const Element = styled.button<AuthUserProps>`
  ${({ theme, ...props }) => {
    return css`
      padding: 0.5rem 0 0.5rem;
      position: relative;
    `;
  }};
`;
