import React from 'react';
import { Element, UserDataContainer, UserDataWrapper } from './DropdownUser.styles';
import Text from '@/components/typography/Text/Text';
import SignOutIcon from '@/components/icons/SignOutIcon/SignOutIcon';
import { Hr } from '@/components/separator/HorizontalRule/HorizontalRule.styles';
import { ACCOUNT_LINK, API_URL } from '@/helpers/browser';
import UserCicleIcon from '@/components/icons/UserCicleIcon/UserCicleIcon';
import AvatarUserInitials from '@/components/navbar/AuthUserButton/DropdownUser/AvatarUserInitials';

type DropdownUserProps = {
  user: UserType | null;
};
const DropdownUser = ({ user }: DropdownUserProps) => {
  const handleLogout = async (e: MouseEvent | any) => {
    e.preventDefault();

    const _token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    try {
      const response = await fetch(API_URL.logout, {
        method: 'POST',
        credentials: 'same-origin',
        // mode: 'cors',
        body: JSON.stringify({ _token: _token }),
        headers: {
          'Content-Type': 'application/json',
          Cookies: document.cookie,
        },
      });

      const data = await response.json();
      if (data) {
        window.location.reload();
      }
    } catch (e) {
      // todo report error to bugsnag manually??
      console.log(`ERROR`, e);
    }
  };

  return (
    <Element>
      <UserDataWrapper>
        <AvatarUserInitials>{user?.initials}</AvatarUserInitials>
        <UserDataContainer>
          <Text size="sm" as="strong" weight="bold">
            {user?.given_name} {user?.family_name}
          </Text>
          <Text size="xs" as="span" color="neutral_600">
            {user?.email}
          </Text>
          <Text size="sm" weight="light">
            {user?.company?.name}
          </Text>
        </UserDataContainer>
      </UserDataWrapper>
      <Hr />
      <ul>
        <li>
          <a href={ACCOUNT_LINK.profile}>
            <UserCicleIcon width={24} color="neutral_600" /> Perfil
          </a>
        </li>
        {/*<li>*/}
        {/*  <a href="#">*/}
        {/*    <CompanyIcon width={24} /> Área do Agente*/}
        {/*  </a>*/}
        {/*</li>*/}
        <li>
          <a href={ACCOUNT_LINK.logout} onClick={handleLogout}>
            <SignOutIcon width={24} color="neutral_600" /> Sair
          </a>
        </li>
      </ul>
    </Element>
  );
};

export default DropdownUser;
