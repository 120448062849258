import styled, { css } from 'styled-components';

export const MenuList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.spacing.base};
`;

export const MenuListItem = styled.li`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      position: relative;
      padding: ${theme.spacing.xs} 0;

      > a {
        font-size: ${theme.typography.font_size.text_xs};
        color: ${theme.color.primary_400};
        display: flex;
        align-items: center;
        gap: ${theme.spacing.xs};
        text-transform: uppercase;
        &:hover {
          color: ${theme.color.primary_500};
        }
      }
    `;
  }};
`;
