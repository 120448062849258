import React, { ReactNode } from 'react';
import { Element } from './AvatarUserInitials.styles';
import Text from '@/components/typography/Text/Text';

export type AvatarUserInitialsProps = {
  children?: ReactNode;
  size?: 'xs' | 'sm' | 'base';
};

const AvatarUserInitials = ({ children, size = 'base', ...props }: AvatarUserInitialsProps) => {
  return (
    <Element size={size}>
      <Text as="span" size={size}>
        {children}
      </Text>
    </Element>
  );
};

export default AvatarUserInitials;
