import React from 'react';

export interface MagnifierIconProps {}

const MagnifyingGlassIcon = (props: MagnifierIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5306 20.4691L16.8365 15.776C18.1971 14.1426 18.8755 12.0475 18.7307 9.92666C18.5859 7.8058 17.629 5.8224 16.0591 4.38907C14.4892 2.95575 12.4271 2.18284 10.3019 2.23114C8.17663 2.27944 6.15181 3.14523 4.64864 4.6484C3.14547 6.15157 2.27968 8.17638 2.23138 10.3016C2.18308 12.4269 2.95599 14.489 4.38932 16.0589C5.82265 17.6288 7.80604 18.5856 9.92691 18.7304C12.0478 18.8752 14.1428 18.1968 15.7762 16.8363L20.4693 21.5304C20.539 21.6 20.6218 21.6553 20.7128 21.693C20.8038 21.7307 20.9014 21.7501 21 21.7501C21.0985 21.7501 21.1961 21.7307 21.2871 21.693C21.3782 21.6553 21.4609 21.6 21.5306 21.5304C21.6003 21.4607 21.6556 21.3779 21.6933 21.2869C21.731 21.1959 21.7504 21.0983 21.7504 20.9997C21.7504 20.9012 21.731 20.8036 21.6933 20.7126C21.6556 20.6215 21.6003 20.5388 21.5306 20.4691ZM3.74997 10.4997C3.74997 9.1647 4.14585 7.85966 4.88755 6.74963C5.62925 5.63959 6.68346 4.77443 7.91686 4.26354C9.15026 3.75265 10.5075 3.61897 11.8168 3.87943C13.1262 4.13988 14.3289 4.78275 15.2729 5.72675C16.2169 6.67076 16.8598 7.87349 17.1203 9.18287C17.3807 10.4922 17.2471 11.8494 16.7362 13.0828C16.2253 14.3162 15.3601 15.3704 14.2501 16.1121C13.14 16.8538 11.835 17.2497 10.5 17.2497C8.71037 17.2477 6.99463 16.5359 5.72919 15.2705C4.46375 14.0051 3.75195 12.2893 3.74997 10.4997Z"
        fill="white"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
