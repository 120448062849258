import React, { ReactNode, useEffect } from 'react';
import { Button } from './NavbarToggle.styles';
import HamburguerIcon from '@/components/icons/HamburguerIcon';
import { useNavbarContext } from '@/components/navbar/context/NavbarContext';
import { useRouter } from 'next/router';

export interface NavbarToggleProps {
  open?: boolean;
  className?: string;
  children?: ReactNode;
}

const NavbarToggle = (props: NavbarToggleProps) => {
  const { open, toggle } = useNavbarContext();
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // const menu = document.querySelector('#menuId');
    // const body = document.querySelector('body');
    // if (menu && body) {
    //   body.style.overflow = 'hidden';
    //   menu.classList.toggle('visible');
    // }

    toggle();
    // console.log(menu);
  };

  useEffect(() => {
    const handleRouteComplete = (url: string) => {
      if (!open) return;
      if (open) {
        toggle();
      }
    };

    router.events.on('routeChangeComplete', handleRouteComplete);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  });

  return (
    <Button onClick={handleClick}>
      <HamburguerIcon />
      <span>Browse</span>
    </Button>
  );
};

export default NavbarToggle;
