import useSWR, { Fetcher } from 'swr';
import { API_URL } from '@/helpers/browser';
import { useMemo } from 'react';

type DataResponse = {
  data: UserType;
};

const fetcher: Fetcher<DataResponse, any> = (path: string) => {
  return fetch(path, {
    method: 'GET',
    credentials: 'include',
    // mode: 'cors',
  }).then((res) => res.json());
};

export default function useLoggedUser() {
  const { data, isLoading, error } = useSWR(API_URL.user, fetcher, {
    // refreshInterval: 100,
  });

  const user: UserType | null = useMemo(() => {
    if (data?.data) {
      return {
        ...data.data,
      };
    }

    return null;
  }, [data?.data]);

  return {
    user,
    isLoading,
    error,
  };
}
