import React, { ReactNode } from 'react';
import Link from 'next/link';
import { MenuList, MenuListItem } from './ContactMenu.styles';
import DropdownOfficePhone from '@/components/navigation/ContactMenu/DropdownOfficePhone/DropdownOfficePhone';
import { Text } from '@/components/typography';
import { Dropdown } from '@/components/dropdown';
import { useAppContext } from '@/context/AppContext';

export interface ContactMenuProps {
  className?: string;
  children?: ReactNode;
}
const ContactMenu = (props: ContactMenuProps) => {
  const { navigation } = useAppContext();
  const { phones } = navigation;

  return (
    <MenuList>
      <MenuListItem>
        <Link href="/institucional/quem-somos.html">Quem Somos</Link>
      </MenuListItem>
      <MenuListItem>
        <Link href="/contato">Fale com um Consultor</Link>
      </MenuListItem>
      <MenuListItem>
        <Dropdown
          spacing="base"
          render={(props) => (
            <Link href="/" {...props}>
              <Text weight="bold">{phones.length ? `(11) ${phones[0]?.phoneNumberList[0]?.phone}` : null}</Text>
              <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3537 7.64604C13.4002 7.69248 13.4371 7.74762 13.4623 7.80832C13.4874 7.86902 13.5004 7.93408 13.5004 7.99979C13.5004 8.0655 13.4874 8.13056 13.4623 8.19126C13.4371 8.25196 13.4002 8.3071 13.3537 8.35354L8.35372 13.3535C8.30729 13.4 8.25214 13.4369 8.19144 13.4621C8.13074 13.4872 8.06568 13.5002 7.99997 13.5002C7.93427 13.5002 7.8692 13.4872 7.8085 13.4621C7.7478 13.4369 7.69266 13.4 7.64622 13.3535L2.64622 8.35354C2.5524 8.25972 2.49969 8.13247 2.49969 7.99979C2.49969 7.86711 2.5524 7.73986 2.64622 7.64604C2.74004 7.55222 2.86729 7.49951 2.99997 7.49951C3.13265 7.49951 3.2599 7.55222 3.35372 7.64604L7.99997 12.2929L12.6462 7.64604C12.6927 7.59955 12.7478 7.56267 12.8085 7.53751C12.8692 7.51235 12.9343 7.4994 13 7.4994C13.0657 7.4994 13.1307 7.51235 13.1914 7.53751C13.2521 7.56267 13.3073 7.59955 13.3537 7.64604ZM7.64622 8.35354C7.69266 8.40003 7.7478 8.43691 7.8085 8.46207C7.8692 8.48723 7.93427 8.50018 7.99997 8.50018C8.06568 8.50018 8.13074 8.48723 8.19144 8.46207C8.25214 8.43691 8.30729 8.40003 8.35372 8.35354L13.3537 3.35354C13.4475 3.25972 13.5003 3.13247 13.5003 2.99979C13.5003 2.86711 13.4475 2.73986 13.3537 2.64604C13.2599 2.55222 13.1327 2.49951 13 2.49951C12.8673 2.49951 12.74 2.55222 12.6462 2.64604L7.99997 7.29291L3.35372 2.64604C3.2599 2.55222 3.13265 2.49951 2.99997 2.49951C2.86729 2.49951 2.74004 2.55222 2.64622 2.64604C2.5524 2.73986 2.49969 2.86711 2.49969 2.99979C2.49969 3.13247 2.5524 3.25972 2.64622 3.35354L7.64622 8.35354Z"
                  fill="#FDDBB1"
                />
              </svg>
            </Link>
          )}
        >
          <DropdownOfficePhone />
        </Dropdown>
      </MenuListItem>
    </MenuList>
  );
};

export default ContactMenu;
