import styled, { css } from 'styled-components';
import { scrollingVisible } from '@/styles/utilities';
import { LinkProps } from 'next/link';
import Link from '@/providers/Link';

export const Section = styled.section`
  ${({ theme, ...props }) => {
    return css`
      max-width: 28rem;
      header {
        margin-bottom: 1rem;
      }
    `;
  }};
`;

export const ResultsContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      padding: 0.25rem;

      overflow-y: scroll;
      min-height: 10rem;
      max-height: 40vh;
      ${scrollingVisible};
    `;
  }};
`;

export const ResultItem = styled(Link)<LinkProps>`
  ${({ theme, ...props }) => {
    return css`
      border: 2px solid ${theme.color.neutral_100};
      text-align: left;
      padding: 1rem 0.5rem;
      border-radius: 0.5rem;

      span {
        display: block;
        color: ${theme.color.secondary_400};
      }

      cite,
      p {
        display: block;
        font-style: normal;
      }

      h3,
      span,
      cite {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      &:hover {
        background: ${theme.color.neutral_100}};
      }
      &:active {
        background: ${theme.color.neutral_200};
      }
      &:focus {
        background: ${theme.color.neutral_100};
      }
    `;
  }};
`;

export const ResultItemPlaceholder = styled.div`
  ${({ theme, ...props }) => {
    return css`
      border: 2px solid ${theme.color.neutral_100};
      text-align: left;
      padding: 1rem 0.5rem;
      border-radius: 0.5rem;
      width: 100%;
      min-height: 6rem;

      background: ${theme.color.neutral_100};

      @keyframes react-placeholder-pulse {
        0% {
          opacity: 0.3;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0.3;
        }
      }

      animation: react-placeholder-pulse 1.5s infinite;
    `;
  }};
`;
