import styled, { css } from 'styled-components';
import { AvatarUserInitialsProps } from './AvatarUserInitials';

export const Element = styled.span<AvatarUserInitialsProps>`
  ${({ theme, size, ...props }) => {
    return css`
      border: 1px solid ${theme.color.neutral_400};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;

      border-radius: 100%;
      background: ${theme.color.neutral_100};

      span {
        color: ${theme.color.neutral_800};
        font-family: ${theme.typography.font_family.title} !important;
        font-weight: ${theme.typography.font_weight.title_semi_bold};
      }

      ${size === 'xs' &&
      css`
        width: 1.3rem;
        height: 1.3rem;
      `};
      ${size === 'sm' &&
      css`
        width: 1.5rem;
        height: 1.5rem;
      `};
      ${size === 'base' &&
      css`
        width: 2rem;
        height: 2rem;
      `};
    `;
  }};
`;
