import React from 'react';
import useSWR, { Fetcher } from 'swr';
import { ResultItem, ResultsContainer, Section } from '@/features/search/SearchResult/SearchResult.styles';
import Text from '@/components/typography/Text/Text';
import Title from '@/components/typography/Title/Title';
import { siteUrl } from '@/helpers/browser';
import SearchLoading from '@/features/search/SearchResult/SearchLoading';
import SearchError from '@/features/search/SearchResult/SearchError';
import SearchNotFound from '@/features/search/SearchResult/SearchNotFound';

type DataResponse = {
  data: [
    {
      title: string;
      excerpt: string;
      permalink: string;
    }
  ];
};

const fetcher: Fetcher<DataResponse, any> = async (args: string) => {
  let path = args[0];
  let q = args[1];
  if (q.length > 1) {
    const res = await fetch(`${path}?q=${q}`, {
      method: 'GET',
      credentials: 'same-origin',
      mode: 'cors',
    });
    return await res.json();
  }
  return new Promise((resolve) => resolve({ data: [] }));
};

export interface SearchResultProps {
  term?: string | null;
  toggle: () => void;
}
const SearchResult = ({ term, toggle }: SearchResultProps) => {
  const { data, isLoading, error } = useSWR([`/api/search`, term], fetcher);

  if (term && term.length <= 1) return null;

  if (isLoading) return <SearchLoading />;
  if (error) return <SearchError />;

  const results = data?.data || [];
  if (!results.length) return <SearchNotFound />;

  const handleClick = (event: React.MouseEvent) => {
    // event.preventDefault();
    toggle();
  };

  return (
    <Section>
      <header>
        <Text size="sm">
          Total de <strong>({results.length}) </strong>{' '}
          {(results.length >= 2 && `itens encontrados`) || `item encontrado`} para sua pesquisa:
        </Text>
      </header>
      <ResultsContainer>
        {results.map((result, index) => (
          <ResultItem key={`${result.permalink}-${index}`} href={siteUrl(result.permalink, true)} onClick={handleClick}>
            <Title as="h3" color="secondary_500">
              {result.title}
            </Title>
            <Text as="span" size="sm">
              {siteUrl(result.permalink, true)}
            </Text>
            <Text as="cite" size="sm">
              {result.excerpt}
            </Text>
          </ResultItem>
        ))}
      </ResultsContainer>
    </Section>
  );
};

export default SearchResult;
