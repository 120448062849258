import styled, { css } from 'styled-components';
import { OffCanvasOverlayProps } from './OffCanvasOverlay';

export const Element = styled.div<Pick<OffCanvasOverlayProps, 'open'>>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.color.dark};
  opacity: 0;
  visibility: hidden;
  z-index: 2000;

  transition: ${({ theme }) => theme.transition.all};

  ${({ theme, open }) =>
    open &&
    css`
      visibility: visible;
      opacity: ${({ theme }) => theme.opacity[60]};
    `}
`;
