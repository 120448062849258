import React, { ReactNode, useEffect } from 'react';
import { Element } from './OffCanvasOverlay.styles';

export interface OffCanvasOverlayProps {
  open: boolean;
  toggle: () => void;
  scrolling?: boolean;
  className?: string;
  children?: ReactNode;
}

const OffCanvasOverlay = ({ open, scrolling, toggle, ...props }: OffCanvasOverlayProps) => {
  useEffect(() => {
    if (scrolling === false) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }, [open, scrolling]);
  return <Element open={open} onClick={toggle} {...props} />;
};

export default OffCanvasOverlay;
