import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme }) => {
    return css`
      display: none;
      @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
        background-color: ${theme.color.secondary_500};
        display: flex;
        align-items: center;
        justify-items: center;
        position: relative;
        z-index: 2000;
      }
    `;
  }};
`;
