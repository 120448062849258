import React, { useEffect, useRef, useState } from 'react';
import TextField from '@/components/form/TextField/TextField';
import { Element } from '@/features/search/SearchForm/SearchForm.styles';
import SearchResult from '@/features/search/SearchResult/SearchResult';
import { useLocalStorage } from '@/hooks/useLocalStorage';

export interface SearchFormProps {
  toggle: () => void;
}

const SearchForm = ({ toggle }: SearchFormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [store, saveStore] = useLocalStorage('searchTerm', '');
  const [term, setTerm] = useState(store);

  const handleChange = () => {
    if (inputRef?.current?.value.length) {
      setTerm(() => inputRef?.current?.value || '');
      saveStore(inputRef?.current?.value || '');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [inputRef]);

  return (
    <>
      <Element>
        <TextField
          bgIcon="search"
          ref={inputRef}
          type="text"
          name="search"
          autoFocus
          defaultValue={store || ''}
          // placeholder="Pesquisar no site www.kangaroo.com.br"
          placeholder="Exemplo: australia"
          autoCapitalize="off"
          autoCorrect="off"
          aria-autocomplete="none"
          spellCheck="false"
          onChange={handleChange}
        />
        <SearchResult term={term} toggle={toggle} />
      </Element>
    </>
  );
};

export default SearchForm;
