import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      padding: ${theme.spacing.sm} 0 ${theme.spacing.sm} ${theme.spacing.base};
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.color.neutral_100};
      height: ${({ theme }) => theme.spacing.lg};
      min-height: 64px;
      width: 100%;
    `;
  }};
`;
