import React, { ReactNode, useEffect } from 'react';
import { FlexContainer, Marker, NavbarContainer } from '@/components/navbar/Navbar/Navbar.styles';
import NavbarToggle from '@/components/navbar/NavbarToggle/NavbarToggle';
import NavbarProvider from '@/components/navbar/context/NavbarProvider';
import observerNavbar from '@/lib/observerNavbar';

export interface NavbarProps {
  variant?: 'primary' | 'default' | 'sticky' | 'mobile' | 'animated';
  className?: string;
  children?: ReactNode;
}

const Navbar = ({ variant = 'default', children }: NavbarProps) => {
  useEffect(() => {
    let observer: IntersectionObserver | undefined;
    if (variant === 'animated') {
      observer = observerNavbar();
    }

    // unmount
    return () => {
      return observer?.disconnect();
    };
  }, [variant]);

  return (
    <NavbarProvider>
      <NavbarContainer variant={variant} id="main-header">
        <FlexContainer>
          <NavbarToggle />
          {children}
        </FlexContainer>
      </NavbarContainer>
      {variant === 'animated' && <Marker id="marker" />}
    </NavbarProvider>
  );
};

export default Navbar;
