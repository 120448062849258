import React from 'react';
import styled from 'styled-components';

interface LogozHorizontalProps {
  color?: 'default' | 'white';
}

const Svg = styled.svg`
  height: 2.5rem;
  width: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    max-height: 3.554315rem; // 56.869px original height
    min-height: 56.869px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    //height: 3.554315rem; // 56.869px original height
    //max-width: 146.65px;
    //max-height: 3.554315rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xl}) {
    min-height: 46.87px;
  }
`;

const LogoHorizontal = ({ color = 'default' }: LogozHorizontalProps) => {
  const bgColor = color === 'default' ? '#b1916a' : '#ffffff';
  const textColor = color === 'default' ? '#000000' : '#ffffff';
  return (
    <Svg
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="246.646px"
      height="56.869px"
      viewBox="0 0 246.646 56.869"
      enableBackground="new 0 0 246.646 56.869"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              fill={bgColor}
              d="M31.118,55.735c-1.777,0-3.53-0.47-5.068-1.357l-14.652-8.459c-3.127-1.805-5.068-5.17-5.068-8.779
              V20.222c0-3.609,1.941-6.974,5.068-8.779l14.652-8.459c3.077-1.777,7.058-1.777,10.137,0l14.65,8.459
              c3.127,1.805,5.068,5.17,5.068,8.779V37.14c0,3.611-1.941,6.976-5.068,8.779l-14.65,8.459
              C34.647,55.266,32.893,55.735,31.118,55.735z M31.118,2.588c-1.608,0-3.195,0.425-4.587,1.229l-14.653,8.459
              c-2.829,1.634-4.586,4.679-4.586,7.945V37.14c0,3.268,1.757,6.313,4.586,7.945l14.653,8.459c2.785,1.609,6.387,1.608,9.173,0
              l14.652-8.459c2.829-1.633,4.586-4.676,4.586-7.945V20.222c0-3.268-1.757-6.312-4.586-7.945L35.704,3.817
              C34.311,3.013,32.726,2.588,31.118,2.588z"
            />
          </g>
          <g>
            <path
              fill={bgColor}
              d="M38.773,51.163H23.46c-3.284,0-6.344-1.768-7.985-4.609L7.82,33.292c-1.644-2.844-1.644-6.377,0-9.221
              l7.655-13.261c1.642-2.845,4.702-4.61,7.985-4.61h15.313c3.283,0,6.342,1.766,7.985,4.61l7.657,13.261
              c1.642,2.844,1.642,6.377,0,9.221l-7.657,13.262C45.117,49.396,42.056,51.163,38.773,51.163z M23.46,7.163
              c-2.94,0-5.682,1.582-7.152,4.129L8.653,24.553c-1.471,2.546-1.471,5.711,0,8.257l7.655,13.262
              c1.471,2.547,4.211,4.129,7.152,4.129h15.313c2.94,0,5.682-1.582,7.152-4.129l7.655-13.262c1.471-2.546,1.471-5.711,0-8.257
              l-7.655-13.261c-1.471-2.547-4.212-4.129-7.152-4.129H23.46z"
            />
          </g>
        </g>
        <path
          fill={bgColor}
          d="M0.808,12.638c0,0,3.27,10.822,17.108,13.513c13.025,2.53,14.403,12.767,15.671,14.155
          c0.319,0.35,3.52,3.544,9.197,4.417c0,0-8.35-2.773-7.785-8.451c-0.615-2.363,0.976-8.117-7.089-10.353
          c6.268,1.207,7.114,5.652,7.114,5.652s0.591-1.773,1.877-2.186c-2.512,2.127-2.005,7.425,2.104,8.195
          c-4.031-2.414-0.976-4.932-0.976-4.932s2.441,0.205,4.754-0.899c1.746-0.822,4.264,0.488,4.264,0.488s0.488,0.128,0.977-0.231
          c0.487-0.359,0.128-0.744-0.077-0.873c-0.206-0.129-1.208-1.104-1.311-1.85c-0.526-1.208-1.604-1.322-1.604-1.322
          s-0.13-0.193,0.114-0.36c0.245-0.167,0.988-1.015,0.693-1.939c-0.859,0.205-1.566,1.477-1.566,1.477s-0.321-1.837-1.516-2.196
          c-0.411,1.246,0.476,2.607,0.54,2.659c0,0-0.616,1.336-2.287,0.706c-1.67-0.629-3.597-7.09-9.196-8.272
          c-4.393-1.052-10.173,2.929-10.173,2.929S10.312,27.306,0.808,12.638z"
        />
      </g>
      <g>
        <path
          fill={textColor}
          d="M69.858,18.513h1.609v5.925l6.311-5.925h2.12l-7.582,7.084l8.174,8.705h-2.103l-6.92-7.354v7.354h-1.609
          V18.513z"
        />
        <path
          fill={textColor}
          d="M95.332,22.624v11.678h-1.48v-2.008c-0.63,0.766-1.337,1.342-2.12,1.729s-1.64,0.58-2.57,0.58
          c-1.653,0-3.064-0.6-4.234-1.799c-1.171-1.197-1.755-2.656-1.755-4.373c0-1.682,0.591-3.12,1.771-4.314
          c1.18-1.195,2.601-1.793,4.261-1.793c0.959,0,1.827,0.203,2.604,0.611c0.775,0.408,1.458,1.02,2.044,1.836v-2.146H95.332z
           M89.328,23.772c-0.837,0-1.608,0.205-2.315,0.616c-0.709,0.411-1.271,0.989-1.689,1.731c-0.418,0.743-0.628,1.529-0.628,2.359
          c0,0.821,0.212,1.607,0.633,2.357c0.422,0.752,0.988,1.335,1.699,1.754c0.711,0.418,1.475,0.627,2.289,0.627
          c0.821,0,1.602-0.207,2.337-0.622c0.737-0.415,1.304-0.976,1.699-1.683c0.397-0.708,0.595-1.505,0.595-2.391
          c0-1.352-0.443-2.48-1.333-3.389C91.722,24.226,90.627,23.772,89.328,23.772z"
        />
        <path
          fill={textColor}
          d="M99.812,22.624h1.502v2.092c0.602-0.801,1.268-1.4,1.998-1.797c0.73-0.397,1.523-0.596,2.382-0.596
          c0.873,0,1.648,0.221,2.324,0.666c0.677,0.443,1.176,1.041,1.497,1.792c0.323,0.751,0.483,1.921,0.483,3.51v6.011h-1.502v-5.57
          c0-1.346-0.057-2.244-0.168-2.695c-0.174-0.771-0.508-1.354-1.002-1.743c-0.493-0.39-1.138-0.585-1.931-0.585
          c-0.91,0-1.725,0.301-2.443,0.902c-0.719,0.6-1.194,1.344-1.423,2.231c-0.143,0.579-0.216,1.639-0.216,3.177v4.283h-1.502V22.624z"
        />
        <path
          fill={textColor}
          d="M124.386,22.624h1.502v9.305c0,1.64-0.143,2.838-0.43,3.596c-0.393,1.074-1.076,1.9-2.049,2.48
          c-0.973,0.579-2.144,0.869-3.51,0.869c-1.003,0-1.902-0.143-2.7-0.426c-0.797-0.283-1.446-0.663-1.948-1.141
          c-0.5-0.478-0.962-1.176-1.385-2.094h1.632c0.445,0.773,1.019,1.346,1.72,1.718c0.702,0.372,1.573,0.558,2.611,0.558
          c1.025,0,1.891-0.19,2.596-0.572c0.705-0.381,1.209-0.861,1.51-1.438s0.451-1.511,0.451-2.798v-0.602
          c-0.566,0.717-1.258,1.266-2.074,1.648c-0.816,0.383-1.682,0.574-2.6,0.574c-1.073,0-2.084-0.266-3.029-0.799
          c-0.946-0.533-1.677-1.252-2.191-2.152c-0.517-0.902-0.773-1.893-0.773-2.974s0.269-2.087,0.805-3.022
          c0.537-0.933,1.28-1.672,2.229-2.216s1.951-0.815,3.003-0.815c0.875,0,1.689,0.181,2.444,0.542
          c0.756,0.361,1.484,0.957,2.188,1.787V22.624z M119.954,23.772c-0.852,0-1.644,0.205-2.372,0.617
          c-0.73,0.411-1.304,0.98-1.718,1.707c-0.416,0.727-0.623,1.511-0.623,2.355c0,1.281,0.429,2.34,1.289,3.178
          c0.857,0.836,1.971,1.255,3.337,1.255c1.382,0,2.492-0.415,3.333-1.245c0.84-0.83,1.26-1.921,1.26-3.273
          c0-0.881-0.191-1.666-0.58-2.355c-0.385-0.691-0.932-1.236-1.641-1.638C121.53,23.972,120.768,23.772,119.954,23.772z"
        />
        <path
          fill={textColor}
          d="M141.841,22.624v11.678h-1.48v-2.008c-0.629,0.766-1.334,1.342-2.119,1.729
          c-0.783,0.387-1.641,0.58-2.57,0.58c-1.654,0-3.064-0.6-4.234-1.799c-1.17-1.197-1.756-2.656-1.756-4.373
          c0-1.682,0.592-3.12,1.771-4.314c1.18-1.195,2.602-1.793,4.262-1.793c0.959,0,1.826,0.203,2.604,0.611
          c0.775,0.408,1.457,1.02,2.043,1.836v-2.146H141.841z M135.837,23.772c-0.836,0-1.607,0.205-2.314,0.616
          c-0.709,0.411-1.271,0.989-1.689,1.731c-0.418,0.743-0.627,1.529-0.627,2.359c0,0.821,0.211,1.607,0.631,2.357
          c0.422,0.752,0.988,1.335,1.699,1.754c0.713,0.418,1.475,0.627,2.289,0.627c0.824,0,1.602-0.207,2.338-0.622
          s1.303-0.976,1.699-1.683c0.396-0.708,0.596-1.505,0.596-2.391c0-1.352-0.445-2.48-1.334-3.389
          C138.231,24.226,137.138,23.772,135.837,23.772z"
        />
        <path
          fill={textColor}
          d="M145.753,22.624h1.537v1.706c0.457-0.673,0.939-1.175,1.447-1.509c0.508-0.332,1.037-0.498,1.59-0.498
          c0.414,0,0.857,0.133,1.33,0.396l-0.783,1.268c-0.314-0.137-0.58-0.204-0.795-0.204c-0.5,0-0.984,0.206-1.449,0.617
          s-0.818,1.05-1.063,1.915c-0.186,0.666-0.277,2.012-0.277,4.036v3.95h-1.537V22.624z"
        />
        <path
          fill={textColor}
          d="M159.562,22.323c1.799,0,3.291,0.651,4.473,1.953c1.076,1.188,1.613,2.594,1.613,4.219
          c0,1.631-0.568,3.058-1.703,4.277c-1.137,1.22-2.598,1.83-4.383,1.83c-1.793,0-3.258-0.61-4.393-1.83
          c-1.137-1.22-1.705-2.646-1.705-4.277c0-1.617,0.539-3.02,1.613-4.207C156.261,22.978,157.755,22.323,159.562,22.323z
           M159.556,23.794c-1.248,0-2.322,0.461-3.221,1.385c-0.896,0.922-1.346,2.039-1.346,3.348c0,0.846,0.203,1.632,0.613,2.361
          c0.408,0.73,0.963,1.294,1.66,1.691c0.695,0.396,1.461,0.596,2.293,0.596c0.834,0,1.598-0.199,2.295-0.596
          c0.697-0.397,1.25-0.961,1.658-1.691c0.41-0.729,0.613-1.516,0.613-2.361c0-1.309-0.449-2.426-1.352-3.348
          C161.87,24.255,160.798,23.794,159.556,23.794z"
        />
        <path
          fill={textColor}
          d="M175.054,22.323c1.799,0,3.289,0.651,4.473,1.953c1.076,1.188,1.613,2.594,1.613,4.219
          c0,1.631-0.568,3.058-1.705,4.277c-1.135,1.22-2.596,1.83-4.381,1.83c-1.791,0-3.256-0.61-4.393-1.83
          c-1.135-1.22-1.703-2.646-1.703-4.277c0-1.617,0.537-3.02,1.613-4.207C171.753,22.978,173.247,22.323,175.054,22.323z
           M175.05,23.794c-1.252,0-2.324,0.461-3.223,1.385c-0.896,0.922-1.346,2.039-1.346,3.348c0,0.846,0.205,1.632,0.613,2.361
          c0.41,0.73,0.963,1.294,1.658,1.691c0.697,0.396,1.463,0.596,2.297,0.596c0.83,0,1.596-0.199,2.293-0.596
          c0.695-0.397,1.25-0.961,1.658-1.691c0.41-0.729,0.615-1.516,0.615-2.361c0-1.309-0.451-2.426-1.352-3.348
          C177.362,24.255,176.292,23.794,175.05,23.794z"
        />
        <path fill={textColor} d="M190.884,20.059v-1.546h8.65v1.546h-3.52v14.243h-1.611V20.059H190.884z" />
        <path
          fill={textColor}
          d="M208.19,22.323c1.799,0,3.289,0.651,4.473,1.953c1.074,1.188,1.613,2.594,1.613,4.219
          c0,1.631-0.568,3.058-1.705,4.277c-1.135,1.22-2.596,1.83-4.381,1.83c-1.791,0-3.256-0.61-4.393-1.83s-1.703-2.646-1.703-4.277
          c0-1.617,0.535-3.02,1.613-4.207C204.89,22.978,206.384,22.323,208.19,22.323z M208.185,23.794c-1.25,0-2.322,0.461-3.221,1.385
          c-0.896,0.922-1.346,2.039-1.346,3.348c0,0.846,0.205,1.632,0.613,2.361c0.41,0.73,0.961,1.294,1.658,1.691
          c0.697,0.396,1.461,0.596,2.295,0.596c0.832,0,1.598-0.199,2.295-0.596c0.695-0.397,1.25-0.961,1.658-1.691
          c0.41-0.729,0.615-1.516,0.615-2.361c0-1.309-0.451-2.426-1.352-3.348C210.499,24.255,209.427,23.794,208.185,23.794z"
        />
        <path
          fill={textColor}
          d="M217.962,22.624h1.502v5.452c0,1.331,0.072,2.247,0.215,2.748c0.215,0.716,0.627,1.28,1.232,1.696
          c0.605,0.414,1.326,0.621,2.166,0.621c0.838,0,1.549-0.201,2.133-0.605s0.986-0.936,1.211-1.594
          c0.148-0.451,0.225-1.406,0.225-2.866v-5.452h1.533v5.731c0,1.61-0.186,2.823-0.563,3.639c-0.375,0.815-0.941,1.454-1.695,1.916
          c-0.754,0.46-1.701,0.692-2.84,0.692c-1.137,0-2.086-0.232-2.85-0.692c-0.762-0.462-1.33-1.106-1.705-1.933
          c-0.377-0.826-0.564-2.069-0.564-3.73V22.624z"
        />
        <path
          fill={textColor}
          d="M232.048,22.624h1.535v1.706c0.459-0.673,0.941-1.175,1.451-1.509c0.506-0.332,1.035-0.498,1.588-0.498
          c0.414,0,0.857,0.133,1.33,0.396l-0.783,1.268c-0.314-0.137-0.58-0.204-0.795-0.204c-0.502,0-0.982,0.206-1.449,0.617
          c-0.465,0.411-0.818,1.05-1.063,1.915c-0.186,0.666-0.279,2.012-0.279,4.036v3.95h-1.535V22.624z"
        />
        <path
          fill={textColor}
          d="M246.198,23.901l-0.965,0.998c-0.805-0.779-1.59-1.17-2.357-1.17c-0.486,0-0.904,0.16-1.254,0.482
          c-0.348,0.322-0.521,0.697-0.521,1.127c0,0.38,0.145,0.741,0.43,1.084c0.287,0.352,0.891,0.762,1.809,1.234
          c1.117,0.58,1.877,1.139,2.279,1.675c0.395,0.544,0.592,1.155,0.592,1.835c0,0.959-0.336,1.771-1.01,2.438
          c-0.674,0.665-1.516,0.998-2.525,0.998c-0.676,0-1.318-0.146-1.93-0.44c-0.613-0.294-1.121-0.698-1.523-1.213l0.945-1.073
          c0.768,0.865,1.58,1.299,2.441,1.299c0.604,0,1.115-0.193,1.539-0.58s0.635-0.84,0.635-1.363c0-0.429-0.139-0.813-0.418-1.148
          c-0.281-0.328-0.912-0.744-1.895-1.244c-1.053-0.545-1.77-1.081-2.15-1.61s-0.57-1.134-0.57-1.814c0-0.887,0.303-1.624,0.908-2.211
          s1.371-0.88,2.295-0.88C244.026,22.323,245.11,22.85,246.198,23.901z"
        />
      </g>
    </Svg>
  );
};

export default LogoHorizontal;
