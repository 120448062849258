import React from 'react';
import theme from '@/styles/theme';
import { SVGIconType } from '@/components/icons/svg-icon-types';

export interface SignOutIconProps extends SVGIconType {}

const SignOutIcon = ({ width = 16, color, ...props }: SignOutIconProps) => {
  let defaultColor = color ? theme.color[color] : 'black';
  let height = props.height || width;
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.75 27C13.75 27.1989 13.671 27.3897 13.5303 27.5303C13.3897 27.671 13.1989 27.75 13 27.75H6C5.53587 27.75 5.09075 27.5656 4.76256 27.2374C4.43437 26.9092 4.25 26.4641 4.25 26V6C4.25 5.53587 4.43437 5.09075 4.76256 4.76256C5.09075 4.43437 5.53587 4.25 6 4.25H13C13.1989 4.25 13.3897 4.32902 13.5303 4.46967C13.671 4.61032 13.75 4.80109 13.75 5C13.75 5.19891 13.671 5.38968 13.5303 5.53033C13.3897 5.67098 13.1989 5.75 13 5.75H6C5.9337 5.75 5.87011 5.77634 5.82322 5.82322C5.77634 5.87011 5.75 5.9337 5.75 6V26C5.75 26.0663 5.77634 26.1299 5.82322 26.1768C5.87011 26.2237 5.9337 26.25 6 26.25H13C13.1989 26.25 13.3897 26.329 13.5303 26.4697C13.671 26.6103 13.75 26.8011 13.75 27ZM27.53 15.47L22.53 10.47C22.3878 10.3375 22.1998 10.2654 22.0055 10.2688C21.8112 10.2723 21.6258 10.351 21.4884 10.4884C21.351 10.6258 21.2723 10.8112 21.2688 11.0055C21.2654 11.1998 21.3375 11.3878 21.47 11.53L25.1887 15.25H13C12.8011 15.25 12.6103 15.329 12.4697 15.4697C12.329 15.6103 12.25 15.8011 12.25 16C12.25 16.1989 12.329 16.3897 12.4697 16.5303C12.6103 16.671 12.8011 16.75 13 16.75H25.1887L21.47 20.47C21.3963 20.5387 21.3372 20.6215 21.2962 20.7135C21.2552 20.8055 21.2332 20.9048 21.2314 21.0055C21.2296 21.1062 21.2482 21.2062 21.2859 21.2996C21.3236 21.393 21.3797 21.4778 21.451 21.549C21.5222 21.6203 21.607 21.6764 21.7004 21.7141C21.7938 21.7518 21.8938 21.7704 21.9945 21.7686C22.0952 21.7668 22.1945 21.7448 22.2865 21.7038C22.3785 21.6628 22.4613 21.6037 22.53 21.53L27.53 16.53C27.6705 16.3894 27.7493 16.1988 27.7493 16C27.7493 15.8012 27.6705 15.6106 27.53 15.47Z"
        fill={defaultColor}
      />
    </svg>
  );
};

export default SignOutIcon;
