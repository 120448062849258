import styled, { css } from 'styled-components';
import { MenuItemProps } from './MenuItem';
import Link from '@/providers/Link';

export const LinkElement = styled(Link)
  // https://styled-components.com/docs/api#shouldforwardprop
  .withConfig({
    shouldForwardProp: (prop) => {
      return !['active', 'caret'].includes(prop);
    },
  })
  .attrs({})<MenuItemProps>`
  ${({ theme, active, caret, ...props }) => {
    return css`
      // ----------------
      // Mobile First
      // ----------------
      font-weight: ${theme.typography.font_weight.text_bold};
      position: relative;
      padding: ${theme.spacing.base};
      display: flex;
      text-transform: uppercase;

      color: ${theme.color.secondary_500};
      border-bottom: 1px solid ${theme.color.neutral_200};

      &:hover,
      &:active {
        background-color: ${theme.color.neutral_200};
      }

      transition: ${theme.transition.color};

      &:after {
        ${caret &&
        css`
          content: '';
          position: absolute;
          right: 1rem;
          top: 0;
          bottom: 0;
          width: ${theme.spacing.base};
          background-position: center 50%;
          background-repeat: no-repeat;
          transition: all 0.3s ease-in-out;
          background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%208C14%208.13261%2013.9473%208.25979%2013.8536%208.35355C13.7598%208.44732%2013.6326%208.5%2013.5%208.5H8.5V13.5C8.5%2013.6326%208.44732%2013.7598%208.35355%2013.8536C8.25979%2013.9473%208.13261%2014%208%2014C7.86739%2014%207.74021%2013.9473%207.64645%2013.8536C7.55268%2013.7598%207.5%2013.6326%207.5%2013.5V8.5H2.5C2.36739%208.5%202.24021%208.44732%202.14645%208.35355C2.05268%208.25979%202%208.13261%202%208C2%207.86739%202.05268%207.74021%202.14645%207.64645C2.24021%207.55268%202.36739%207.5%202.5%207.5H7.5V2.5C7.5%202.36739%207.55268%202.24021%207.64645%202.14645C7.74021%202.05268%207.86739%202%208%202C8.13261%202%208.25979%202.05268%208.35355%202.14645C8.44732%202.24021%208.5%202.36739%208.5%202.5V7.5H13.5C13.6326%207.5%2013.7598%207.55268%2013.8536%207.64645C13.9473%207.74021%2014%207.86739%2014%208Z%22%20fill%3D%22%23285164%22%2F%3E%3C%2Fsvg%3E');
          ${active &&
          css`
            background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%208C14%208.13261%2013.9474%208.25979%2013.8536%208.35355C13.7598%208.44732%2013.6326%208.5%2013.5%208.5H2.50003C2.36742%208.5%202.24025%208.44732%202.14648%208.35355C2.05271%208.25979%202.00003%208.13261%202.00003%208C2.00003%207.86739%202.05271%207.74021%202.14648%207.64645C2.24025%207.55268%202.36742%207.5%202.50003%207.5H13.5C13.6326%207.5%2013.7598%207.55268%2013.8536%207.64645C13.9474%207.74021%2014%207.86739%2014%208Z%22%20fill%3D%22%23285164%22%2F%3E%3C%2Fsvg%3E');
          `}
        `}
      }

      // ----------------------
      // Mobile Dropdown menu
      // ----------------------
      @media screen and (max-width: ${theme.breakpoint.lg}) {
        + div {
          display: grid;
          grid-template-rows: 0fr;
          transition: grid-template-rows 600ms;
          > div {
            overflow: hidden;
          }
        }

        &[aria-expanded='true'] {
          &:after {
            background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14%208C14%208.13261%2013.9474%208.25979%2013.8536%208.35355C13.7598%208.44732%2013.6326%208.5%2013.5%208.5H2.50003C2.36742%208.5%202.24025%208.44732%202.14648%208.35355C2.05271%208.25979%202.00003%208.13261%202.00003%208C2.00003%207.86739%202.05271%207.74021%202.14648%207.64645C2.24025%207.55268%202.36742%207.5%202.50003%207.5H13.5C13.6326%207.5%2013.7598%207.55268%2013.8536%207.64645C13.9474%207.74021%2014%207.86739%2014%208Z%22%20fill%3D%22%23285164%22%2F%3E%3C%2Fsvg%3E');
          }
          + div[aria-hidden='true'] {
            grid-template-rows: 1fr;
          }
        }
      }

      // --------------
      // Desktop
      // --------------
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        gap: ${theme.spacing.xs};
        border: 0;
        border-bottom: 3px solid transparent;
        color: ${theme.color.white};
        background-color: transparent;
        padding: ${theme.spacing.xs} ${theme.spacing.base};
        // This will prevent the dropdown menu to hide when the mouse leave the anchor element
        margin: ${theme.spacing.sm} 0;

        transition: ${theme.transition.color}, border-bottom-color 300ms ease-in-out;

        &:hover {
          background-color: transparent;
          border-bottom: 3px solid ${theme.color.white};
          &:after {
            transform: rotate(180deg);
          }
        }

        // ---------------------------------------------------
        // Makes the tab active/focus shows the dropdown menu
        // ---------------------------------------------------
        :active,
        :focus {
          background-color: transparent;
          &:after {
            transform: rotate(180deg);
          }
          + div {
            width: 100%;
            height: auto;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
        // ----

        &:after {
          ${caret &&
          css`
            position: initial;
            top: initial;
            left: initial;
            display: flex;
            width: ${theme.spacing.base};
            background-position: center 50%;
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 0.3s ease-in-out;
          `}
        }

        ${caret &&
        css`
          &:after {
            // Desktop Idle State
            background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M13.3538%206.35403L8.35375%2011.354C8.30732%2011.4005%208.25217%2011.4374%208.19147%2011.4626C8.13077%2011.4877%208.06571%2011.5007%208%2011.5007C7.9343%2011.5007%207.86923%2011.4877%207.80853%2011.4626C7.74783%2011.4374%207.69269%2011.4005%207.64625%2011.354L2.64625%206.35403C2.55243%206.26021%202.49973%206.13296%202.49973%206.00028C2.49973%205.8676%202.55243%205.74035%202.64625%205.64653C2.74007%205.55271%202.86732%205.5%203%205.5C3.13269%205.5%203.25993%205.55271%203.35375%205.64653L8%2010.2934L12.6463%205.64653C12.6927%205.60007%2012.7479%205.56322%2012.8086%205.53808C12.8693%205.51294%2012.9343%205.5%2013%205.5C13.0657%205.5%2013.1308%205.51294%2013.1915%205.53808C13.2521%205.56322%2013.3073%205.60007%2013.3538%205.64653C13.4002%205.69298%2013.4371%205.74813%2013.4622%205.80883C13.4873%205.86953%2013.5003%205.93458%2013.5003%206.00028C13.5003%206.06598%2013.4873%206.13103%2013.4622%206.19173C13.4371%206.25242%2013.4002%206.30757%2013.3538%206.35403Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
          }
        `}
        ${active &&
        css`
          border-bottom: ${theme.border_width.lg} solid ${theme.color.white};
        `}
      } // Media Desktop
    `;
  }}; // END CSS THEME FN
`;
