import React from 'react';
import { LogoHorizontal, LogoIcon } from './index';

interface LogoProps {
  /**
   * The logo version*/
  variant?: 'horizontal' | 'icon';
  /**
   * The color options for the logo*/
  color?: 'default' | 'white';
  /**
   * Logo width in pixels*/
  width?: number | undefined;
  /**
   * Logo height in pixels*/
  height?: number | undefined;
}

const Logo = ({ variant = 'horizontal', color = 'default', width, height }: LogoProps) => {
  return variant === 'horizontal' ? (
    <LogoHorizontal color={color} />
  ) : (
    <LogoIcon color={color} width={width} height={height} />
  );
};

export default Logo;
