import React, { ReactNode, MouseEvent } from 'react';
import { Element } from './OffCanvasBody.styles';

export interface OffCanvasBodyProps {
  className?: string;
  children?: ReactNode;
}

const OffCanvasBody = ({ children, ...props }: OffCanvasBodyProps) => {
  return <Element {...props}>{children}</Element>;
};

export default OffCanvasBody;
