import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import { Element } from './OffCanvas.styles';
import OffCanvasContext from '@/components/offcanvas/context/OffCanvasContext';
import OffCanvasOverlay from '@/components/offcanvas/OffCanvasOverlay/OffCanvasOverlay';

export interface OffCanvasProps extends HTMLAttributes<HTMLDivElement> {
  /** Whether to show or hide the canvas.*/
  open: boolean;
  /** The function to toggle the canvas.*/
  toggle: () => void;
  /** Canvas direction where it shows up.*/
  canvas?: 'start' | 'end';
  /** The size in vw for the canvas.*/
  size: 'sm' | 'md' | 'lg' | 'full';
  /** The scree defines which device the OffCanvas will be shown.*/
  screen?: 'mobile' | 'desktop' | 'all';
  /** Whether to keep the body scrollable or not.*/
  scrolling: boolean;
  className?: string;
  children?: ReactNode;
}

const OffCanvas = ({
  open,
  toggle,
  canvas = 'start',
  screen = 'all',
  scrolling = false,
  size = 'sm',
  children,
  ...props
}: OffCanvasProps) => {
  const context = useMemo(() => ({ open, toggle }), [open, toggle]);
  return (
    <OffCanvasContext.Provider value={context}>
      <Element open={open} canvas={canvas} size={size} screen={screen} {...props}>
        {children}
      </Element>
      <OffCanvasOverlay open={open} toggle={toggle} scrolling={scrolling} />
    </OffCanvasContext.Provider>
  );
};

export default OffCanvas;
