import React from 'react';
import Text from '../../../components/typography/Text/Text';
import { Section } from '@/features/search/SearchResult/SearchResult.styles';

export interface SearchNotFoundProps {}

const SearchNotFound = ({ ...props }: SearchNotFoundProps) => {
  return (
    <Section>
      <Text size="sm">Nenhum resultado encontrado</Text>
    </Section>
  );
};

export default SearchNotFound;
