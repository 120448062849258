import styled, { css } from 'styled-components';
import { NavigationProps } from '@/components/navigation/Navigation/Navigation';

export const Nav = styled.nav<NavigationProps>`
  //
`;

export const NavList = styled.ul`
  ${({ theme, ...props }) => {
    return css`
      margin: 0 -${theme.spacing.base};
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        display: flex;
      }
    `;
  }};
`;

export const NavListItem = styled.li`
  ${({ theme, ...props }) => {
    return css`
      border-bottom: 1px solid ${theme.color.neutral_200};

      // ------------------------------
      // Desktop Dropdown Menu
      // ------------------------------
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        :hover {
          // MenuItem::LinkElement
          > a {
            border-bottom: 3px solid ${theme.color.white};
            &:after {
              transform: rotate(180deg);
            }
            &:hover {
              color: ${theme.color.white};
            }
          }
          // Expand Dropdown SubMenuContainer
          &.show-submenu > div {
            width: 100%;
            height: auto;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      }

      @media screen and (min-width: ${theme.breakpoint.lg}) {
        display: flex;
        align-items: center;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    `;
  }};
`;

export const ExchangeRateWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        display: none;
        visibility: hidden;
      }
      margin: 2rem 0;
      > * {
        color: ${theme.color.dark}!important;
      }
      > div > div {
        + span {
          &:before {
            border-color: ${theme.color.neutral_200};
          }
        }
      }
    `;
  }};
`;
