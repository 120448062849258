import { isMobile } from '@/helpers/browser';

function observerNavbar(): IntersectionObserver | undefined {
  if (isMobile()) return;

  const header = document.querySelector('header#main-header') as HTMLElement;
  let marker = document.querySelector('#marker');

  let options = {
    // root: null,
    // rootMargin: '0px',
    // threshold: [0.0, 0.5, 1.0],
  };

  if (marker && header) {
    const intersectionObserver = new IntersectionObserver((entries, ...rest) => {
      // If intersectionRatio is 0, the target is out of view
      entries.forEach((entry) => {
        // Offscreen
        if (!entry.isIntersecting) {
          header.classList.add('animated');
          header.style.top = '0px';
        } else {
          header.style.top = '-100px';
          if (entry.intersectionRatio == 1.0) {
            header.classList.remove('animated');
          }
        }
      });
    }, options);

    // start observing
    intersectionObserver.observe(marker);

    return intersectionObserver;
  }
}

export default observerNavbar;
