import React, { ReactNode } from 'react';
import Link from '@/providers/Link';
import { Text, Title } from '@/components/typography';
import {
  SubMenuColumnContainer,
  SubMenuColumnHeading,
  SubMenuColumnMenu,
  SubMenuContainer,
  SubMenuList,
  SubMenuListItem,
} from './SubMenuColumns.styles';
import { useRouter } from 'next/router';

export interface SubMenuProps {
  /** Parent ID is the way to hide the menu on page transitions for NextLink component.*/
  parentId: string;
  title: string;
  submenu: SubMenuData[];
  className?: string;
  children?: ReactNode;
}

const SubMenuColumns = ({ parentId, title, submenu }: SubMenuProps) => {
  const { asPath, isReady } = useRouter();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const parentElement = document.querySelector(`#${parentId}`);
    if (parentElement) {
      parentElement.classList.remove('show-submenu');
    }
  };

  return (
    <SubMenuContainer aria-labelledby={title} aria-hidden={true}>
      <Text size="md">{title}</Text>
      <SubMenuColumnContainer>
        {submenu.map((menu: SubMenuData) =>
          menu.items.length ? (
            <SubMenuColumnMenu key={menu.continent}>
              <SubMenuColumnHeading toggle={false}>
                <Title as="h3" textCase="uppercase">
                  {menu.continent}
                </Title>
              </SubMenuColumnHeading>
              <SubMenuList>
                {menu.items.map((item: MenuItemData) => (
                  <SubMenuListItem key={item.slug}>
                    <Link
                      href={item.slug}
                      onClick={onClick}
                      aria-label={`Pacotes ${item.title}`}
                      className={asPath === item.slug ? 'active' : ''}
                    >
                      {item.title}
                    </Link>
                  </SubMenuListItem>
                ))}
              </SubMenuList>
            </SubMenuColumnMenu>
          ) : null
        )}
      </SubMenuColumnContainer>
    </SubMenuContainer>
  );
};

export default SubMenuColumns;
