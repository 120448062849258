import React from 'react';
import { MenuItem } from '@/components/navigation/MenuItem';
import SubMenuColumns from '@/components/navigation/DropdownMenu/SubMenuColumns';
import { isMobile } from '@/helpers/browser';
import { useRouter } from 'next/router';

export interface MenuDropdownProps extends NavbarData {
  parentId: string;
}
const DropdownMenu = ({ parentId, title, slug, submenu }: MenuDropdownProps) => {
  const { asPath, isReady } = useRouter();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isMobile(992)) {
      // FIXME: This will perform a full page reload, but will solve the issue
      //  with the DropdownMenu Container not being closed when clicked on the menuItem.
      //  Maybe the ideia here is to use route.event('onRouteChangeComplete)

      // const el = e.currentTarget;
      // const parent = el.closest('li');
      // const hasDropdownMenu = el.nextSibling; // a + div.dropdown-menu
      // if (hasDropdownMenu) {
      //   // console.log(`parent`, parent);
      //   // parent?.classList.toggle('show-submenu');
      //   if (parent) {
      //     // parent?.classList.remove('show-submenu');
      //     // if (parent.classList.contains('show-submenu')) {
      //     //   hasDropdownMenu.style.opacity = '0';
      //     // } else {
      //     //   hasDropdownMenu.style.opacity = '1';
      //     // }
      //
      //     const parentElement = document.querySelector(`#${parentId}`);
      //     if (parentElement) {
      //       parentElement.classList.toggle('show-submenu-off');
      //     }
      //   }
      e.preventDefault();
      location.href = e.currentTarget.href;
      // }
      return;
    }

    // --------------------------------------
    // The logic below only applies to mobile
    // --------------------------------------
    const el = e.currentTarget;
    const child = el.closest('li');
    const isExpanded = el.getAttribute('aria-expanded');

    const hasDropdownMenu = el.nextSibling; // a + div.dropdown-menu

    if (!hasDropdownMenu) {
      return;
    }

    e.preventDefault();

    if (isExpanded === 'true') {
      el.setAttribute('aria-expanded', 'false');
      child?.setAttribute('aria-hidden', 'true');
    } else {
      el.setAttribute('aria-expanded', 'true');
      child?.setAttribute('aria-hidden', 'false');
    }
  };

  return (
    <React.Fragment>
      <MenuItem
        id={title.toLowerCase().replace(/\s/g, '-')}
        href={slug}
        onClick={handleClick}
        onFocus={(e) => {
          //
        }}
        onMouseOver={(e) => {
          // document.body.focus();
        }}
        caret={!!submenu!?.length}
        active={slug === asPath}
        aria-expanded={false}
        aria-controls="dropdown-menu"
      >
        {title}
      </MenuItem>
      {submenu?.length && <SubMenuColumns title={title} submenu={submenu} parentId={parentId} />}
    </React.Fragment>
  );
};

export default DropdownMenu;
