import React, { ReactNode } from 'react';
import Link from 'next/link';
import Logo from '@/components/logo/Logo';
import { Navigation } from '@/components/navigation/Navigation';
import SearchButton from '../navbar/SearchButton/SearchButton';
import { Navbar } from '@/components/navbar/Navbar';
import { NavbarLogo } from '@/components/navbar/Navbar/Navbar.styles';
import NavbarInfo from '@/components/header/NavbarInfo/NavbarInfo';
import { NavbarProps } from '@/components/navbar/Navbar/Navbar';
import AuthUserButton from '@/components/navbar/AuthUserButton/AuthUserButton';

export interface HeaderProps {
  navbar?: NavbarProps['variant'];
  className?: string;
  children?: ReactNode;
}

const Header = ({ navbar = 'animated' }: HeaderProps) => {
  return (
    <>
      {(navbar === 'animated' && <NavbarInfo />) || null}
      <Navbar variant={navbar}>
        <NavbarLogo className="logo">
          <Link href="/">
            <Logo color="white" />
          </Link>
        </NavbarLogo>
        <Navigation />
        <SearchButton />
        <AuthUserButton />
      </Navbar>
    </>
  );
};

export default Header;
