import React, { HTMLAttributes, ReactNode } from 'react';
import { LinkElement } from './MenuItem.styles';

export interface MenuItemProps extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void | undefined;
  /**
   * The Caret prop shows a Caret a Plus/Minus (mobile) or Up/Down (desktop)
   * */
  readonly caret?: boolean | undefined;
  /**
   * The Active state shows the background-color (mobile) or a border-bottom (desktop)
   * */
  readonly active?: boolean | undefined;
  className?: string;
  children?: ReactNode;
}

const MenuItem = ({ href, children, onClick, active, ...props }: MenuItemProps) => {
  return (
    <LinkElement href={href} onClick={onClick} active={active} {...props}>
      {children}
    </LinkElement>
  );
};

export default MenuItem;
