import React, { MouseEvent, ReactNode } from 'react';
import { Section } from '@/features/search/SearchResult/SearchResult.styles';

export interface SearchErrorProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const SearchError = ({ children, ...props }: SearchErrorProps) => {
  return (
    <Section>
      <em>Ocorreu um erro, não foi possível completar sua busca.</em>
    </Section>
  );
};

export default SearchError;
