import React, { ReactNode } from 'react';
import { Text } from '@/components/typography';
import ButtonClose from '@/components/button/ButtonClose/ButtonClose';
import { Element } from './OffCanvasHeader.styles';
import { useOffCanvasContext } from '@/components/offcanvas/context/OffCanvasContext';

export interface OffCanvasHeaderProps {
  id: string;
  title: string;
  className?: string;
  children?: ReactNode;
}

const OffCanvasHeader = ({ id, title, children, ...props }: OffCanvasHeaderProps) => {
  const { open, toggle } = useOffCanvasContext();

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggle();
  };
  return (
    <Element {...props}>
      <Text weight="bold" id={id}>
        {children ?? title}
      </Text>
      <ButtonClose aria-label="Close" onClick={handleClose} />
    </Element>
  );
};

export default OffCanvasHeader;
