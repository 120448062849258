import styled, { css } from 'styled-components';
import { NavbarProps } from '@/components/navbar/Navbar/Navbar';
import Container from '@/components/container/Container';

const bgGradient = css`
  //background: transparent;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 70%, rgba(255, 255, 255, 0) 100%);
`;

// Header
export const NavbarContainer = styled.header<NavbarProps>`
  ${({ theme, variant }) => {
    return css`
      --navbar-height-mobile: 4.5714285714rem; // *  14px = 64px

      background: ${theme.color.secondary_500};

      // Mobile Only
      position: sticky;
      top: 0;
      z-index: 1000;

      min-height: var(--navbar-height-mobile);
      display: flex;
      align-items: center;

      // Small viewports needs a slightly spacing for the logo and search button
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      // Desktop
      @media screen and (min-width: ${theme.breakpoint.lg}) {
        ${() => {
          if (!variant || variant === 'default') {
            return css`
              position: initial;
              // Applies a gradient BG if the navbar is white
              ${bgGradient};
              > div:first-of-type {
                &:after {
                  border-bottom: 1px solid rgb(247 243 239 / 0.5) !important; // theme.color.primary_100
                }
              }
            `;
          }
        }};

        // Stick?
        ${() => {
          if (variant === 'sticky') {
            return css`
              position: sticky;
              top: 0;
            `;
          }
        }};

        // Secondary
        ${() => {
          if (variant === 'primary') {
            return css`
              background-color: ${theme.color.primary_500};
            `;
          }
        }}

        // Animated
        ${() => {
          if (variant === 'animated') {
            return css`
              // Desktop
              @media screen and (min-width: ${theme.breakpoint.lg}) {
                top: -100px;
                min-height: auto;
                // FlexContainer
                > div:first-of-type {
                  border-bottom: 1px solid rgb(247 243 239 / 0.5); // theme.color.primary_100

                  // Header -> Container -> NavList -> NavListItem
                  nav > ul > li {
                    padding-top: 0.695rem;
                    padding-bottom: 0.695rem;
                  }
                }

                &,
                > div:first-of-type {
                  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1), padding-top 0.7s cubic-bezier(0.4, 0, 0.2, 1),
                    padding-bottom 0.7s cubic-bezier(0.4, 0, 0.2, 1);
                }

                svg#logo {
                  transition: scale 0.4s cubic-bezier(0.4, 0, 0.2, 1);
                }

                // Applies a gradient BG if the navbar is white
                ${bgGradient};

                &.animated {
                  position: sticky;
                  top: 0;
                  padding: 0;

                  // FlexContainer
                  > div:first-of-type {
                    border-bottom: 1px solid transparent;
                    padding: 0;
                    margin: 0 auto;

                    nav > ul > li {
                      padding-top: calc(0.695rem / 2);
                      padding-bottom: calc(0.695rem / 2);
                    }

                    // fixes spacing
                    a {
                      margin-left: -0.75rem;
                    }

                    svg#logo {
                      scale: 90%;
                    }
                  }
                  background: ${theme.color.secondary_500};
                }
              }
            `;
          }
        }}
      }
    `;
  }};
`;

// Header -> Container
export const FlexContainer = styled(Container)`
  display: flex;
  align-items: center;

  max-height: 5rem;

  // ---------------
  // Desktop
  // ---------------
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    position: relative; // Makes the dropdown menu base to this element
    justify-content: space-between;
    gap: 0.5rem;
    // disabled as it makes the sticky navbar height super big
    //padding: 0.695rem 0; // Small hack to make the navbar height container stick to 80px on 16 base font-size
    margin: 0.5rem auto;
  }
`;

export const NavbarLogo = styled.div`
  margin-left: auto;
  margin-right: auto;
  > a {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex: 0;
    margin-left: 0;
    > a {
      margin-left: auto;
      margin-right: 0;
      flex-grow: 0;
    }
  }
`;

export const Marker = styled.div`
  visibility: hidden;
`;
