import React, { ReactNode, useState } from 'react';
import NavbarContext from '@/components/navbar/context/NavbarContext';

export interface NavbarProviderProps {
  children?: ReactNode;
}

const NavbarProvider = ({ children }: NavbarProviderProps) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  return <NavbarContext.Provider value={{ open, toggle }}>{children}</NavbarContext.Provider>;
};

export default NavbarProvider;
