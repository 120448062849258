import React, { ReactNode, useEffect, useState } from 'react';
import { SearchButtonElement } from './SearchButton.styles';
import MagnifyingGlassIcon from './MagnifyingGlassIcon';
import { Modal, ModalBody, ModalHeader } from '@/components/modal';
import SearchForm from '@/features/search/SearchForm/SearchForm';

export interface SearchButtonProps {
  className?: string;
  children?: ReactNode;
}

const SearchButton = (props: SearchButtonProps) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      toggle();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, false);

    return () => {
      window.removeEventListener('keydown', handleKeyPress, false);
    };
  });

  return (
    <>
      <SearchButtonElement onClick={(e) => setOpen((prevState) => !prevState)}>
        <MagnifyingGlassIcon />
        <span>Buscar</span>
      </SearchButtonElement>
      <Modal id="modalSearch" open={open} toggle={toggle} position="start">
        <ModalHeader>Buscar no Site</ModalHeader>
        <ModalBody>
          <SearchForm toggle={toggle} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SearchButton;
