import styled from 'styled-components';
import { SearchButtonProps } from './SearchButton';

export const SearchButtonElement = styled.button<SearchButtonProps>`
  font-size: ${({ theme }) => theme.typography.font_size.text_base};
  padding: ${({ theme }) => theme.spacing.sm};
  > span {
    display: none !important;
  }
`;
