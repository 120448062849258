import styled from 'styled-components';
import { NavbarToggleProps } from './NavbarToggle';

export const Button = styled.button<NavbarToggleProps>`
  font-size: ${({ theme }) => theme.typography.font_size.text_base};
  > span {
    // Screen Readers only
    display: none !important;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
    visibility: hidden;
  }
`;
