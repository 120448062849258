import { createContext, useContext } from 'react';

interface NavbarContextType {
  open: boolean;
  toggle: () => void;
}

const NavbarContext = createContext<NavbarContextType>({} as NavbarContextType);

export const useNavbarContext = () => {
  return useContext(NavbarContext);
};

export default NavbarContext;
