import styled, { css } from 'styled-components';
import { HorizontalRuleProps } from './HorizontalRule';

export const Hr = styled.div<HorizontalRuleProps>`
  ${({ theme, spacing, ...props }) => {
    return css`
      border: 0;
      border-bottom: 1px solid ${theme.color.neutral_200};
      ${spacing &&
      css`
        margin: ${theme.spacing[spacing]} 0;
      `};
    `;
  }};
`;
