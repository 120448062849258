import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.base};

      max-width: calc(100vw - 1rem);
      min-width: calc(100vw - 4rem);
      max-height: calc(100vh - 15rem);

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        min-width: 15rem;
      }

      ul li {
        border-radius: ${theme.radius.xxs};
        a {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem;
          border-radius: ${theme.radius.xs};
          &:hover {
            background: ${theme.color.neutral_200};
          }
        }
      }
    `;
  }};
`;

export const UserDataWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: center;
      gap: 0.5rem;
    `;
  }};
`;

export const UserDataContainer = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      align-items: self-start;
      flex-direction: column;
      gap: 0;
    `;
  }};
`;
