import { createContext, useContext } from 'react';

interface OffCanvasType {
  open: boolean;
  toggle: () => void;
}

// const OffCanvasContext = createContext<OffCanvasType | null>(null);
const OffCanvasContext = createContext({} as OffCanvasType);

export const useOffCanvasContext = () => {
  return useContext(OffCanvasContext);
};

export default OffCanvasContext;
